import {
  Grid,
  FormControlLabel,
  TextField,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SiteAreaFormModel } from '@/modules/organization/shared/models/siteArea';
import { requiredFields } from '@/modules/organization/shared/schemas/siteAreaFormSchema';
import EvesSelect from '@/common/components/atoms/select/select';
import { useTranslation } from 'react-i18next';
import {
  phaseAssignmentToGridMapSinglePhased,
  phaseAssignmentToGridMapThreePhased,
} from '@/common/constants/constants';
import { useState } from 'react';
import { isActive } from '@/common/utils/utils';
import { TenantComponents } from '@/common/enums/enums';

interface ILimitsPropsModel {
  siteAreaForm: UseFormReturn<SiteAreaFormModel, any>;
  isDisabled: boolean;
}

const updateAmpValue = (
  maximumPower: any,
  numberOfPhases: any,
  voltage: any
) => {
  let maxPowerAmps = 0;
  maxPowerAmps = Math.floor(maximumPower / voltage);
  let maxPowerPerPhase = 0;
  maxPowerPerPhase = Math.floor(maximumPower / voltage / numberOfPhases);
  return { maxPowerAmps, maxPowerPerPhase,numberOfPhases };
};

const HeaderWrapper = ({ children, title }: any) => {
  return (
    <Grid sx={{
      width: '100%', // Fill width
      // height: '104px', // Hug height
      padding: '16px',
      paddingBottom:'20px',
      marginLeft: '10px',
      marginTop: '16px',
      gap: '12px',
      borderRadius: '8px',
      border: '1px solid #E2E2E2',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Grid sx={{
        width: '100%', // Fill width
        height: '20px', // Hug height
        padding: '0 0 4px 0',
        gap: '10px',
        borderBottom: '1px solid #E2E2E2',
        // border: '1px solid transparent',
        borderBottomWidth: '1px',
      }}>
        <Grid sx={{
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '16.41px',
          textAlign: 'left',
          // background: '#1E1E1E',
        }}>
          {title}
        </Grid>
      </Grid>
      <Grid container direction='row' rowSpacing={1} columnSpacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};

export default function PowerLimits(props: ILimitsPropsModel) {
  const { control, formState, watch } = props.siteAreaForm;
  const { errors } = formState;
  const { t: translate } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<any>(props.siteAreaForm.getValues().smartCharging ? props.siteAreaForm.getValues().smartCharging?.smartChargingtype:'N');
  const formValues:any = props.siteAreaForm.getValues();
  const isGridifyActive = isActive(TenantComponents.GRIDIFY);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'demandManagement' || event.target.value === 'loadBalancing') {
      props.siteAreaForm.setValue('smartCharging.enabled', true);
      // JIRA -[EVES-1680] setting smartCharging at smart charging object and passing 2 values
      if(event.target.value === 'demandManagement'){
        props.siteAreaForm.setValue('smartCharging.smartChargingtype', 'demandManagement');

      }
      else if(event.target.value === 'loadBalancing'){
        props.siteAreaForm.setValue('smartCharging.smartChargingtype', 'loadBalancing');
      }

    }
  };
  const onValueChangeAmp = () => {
    const formValues:any = props.siteAreaForm.getValues();


    props.siteAreaForm.setValue('totalAmpAllPhases', formValues.ampPerPhase*formValues.numberOfPhases);
    props.siteAreaForm.setValue('rating', (formValues.ampPerPhase*formValues.numberOfPhases*formValues.voltage)/1000);

    
  };

  const onValueChangeRating = () => {
    const formValues:any = props.siteAreaForm.getValues();


    props.siteAreaForm.setValue('totalAmpAllPhases', formValues.rating*1000/formValues.voltage);
    props.siteAreaForm.setValue('ampPerPhase', (formValues.rating*1000)/(formValues.voltage*formValues.numberOfPhases));

    
  };

  const voltageOptions = [
    { label: '400', value: 400 },
    { label: '380', value: 380 },
    { label: '346', value: 346 },
    { label: '277', value: 277 },
    { label: '240', value: 240 },
    { label: '230', value: 230 },
    { label: '220', value: 220 },
    { label: '208', value: 208 },
    { label: '120', value: 120 },
    { label: '110', value: 110 },
  ];

  const getPhaseAssignementToGridOptions = (numberOfPhases?: number) => {
    const phaseAssignementToGridOptions: any =
      numberOfPhases == 1
        ? phaseAssignmentToGridMapSinglePhased
        : phaseAssignmentToGridMapThreePhased;
    return phaseAssignementToGridOptions.map(({ label, value }) => ({
      label,
      value: JSON.stringify(value),
    }));
  };
  const numberOfPhases = props.siteAreaForm.getValues('numberOfPhases');
  const phaseAssignementToGridOptions =
    getPhaseAssignementToGridOptions(numberOfPhases);

  const connectedPhaseOptions = [
    { label: `${translate('chargers.single_phase')}`, value: 1 },
    { label: `${translate('chargers.tri_phases')}`, value: 3 },
  ];
  const numberOfConnectedPhaseChanged = () => {
    if (watch('numberOfPhases') === 1) {
      props.siteAreaForm.setValue('phaseAssignment', {
        csPhaseL1: 'L1',
        csPhaseL2: null,
        csPhaseL3: null,
      });
    } else {
      props.siteAreaForm.setValue('phaseAssignment', {
        csPhaseL1: 'L1',
        csPhaseL2: 'L2',
        csPhaseL3: 'L3',
      });
    }
    onValueChangeAmp()
    onValueChangeRating()
  };

  return (
    <>
      <Grid container direction='row' rowSpacing={'16px'} columnSpacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='N'
            value={selectedValue}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value='N'
              control={<Radio />}
              label={
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #242424)',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                  }}
                >
                  No Smart Charging
                </Typography>
              }
            />
            {
              isGridifyActive && (<>
              <FormControlLabel
                name="smartCharging.smartChargingtype"
                value='loadBalancing'
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      color: 'var(--Gray-900, #242424)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: 'normal',
                    }}
                  >
                    Enable Load Balancing
                  </Typography>
                }
              />
              <FormControlLabel
                name="smartCharging.smartChargingtype"
                value='demandManagement'
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      color: 'var(--Gray-900, #242424)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: 'normal',
                    }}
                  >
                    Enable Demand Management
                  </Typography>
                }
              /> </>)
            }
           
          </RadioGroup>
        </Grid>
        {selectedValue !== 'N' && (
          <HeaderWrapper title={selectedValue == 'demandManagement' ? 'Demand Settings' : 'Load Balancing Settings'}>
            <Grid item xs={6}>
              {selectedValue === 'loadBalancing' && (
                <Controller
                  name='smartCharging.setPointAmp'
                  control={props.siteAreaForm.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        onValueChangeAmp();
                      }}
                      id='maximumPower'
                      label={`${translate('Setpoint Per Phase (A)')}`}
                      required={requiredFields['maximumPower']}
                      error={!!errors?.maximumPower}
                      helperText={`${translate(
                        props.siteAreaForm.formState?.errors?.maximumPower
                          ?.message || ' '
                      )}`}
                      fullWidth
                      variant='standard'
                    // disabled={props.isDisabled}
                    />
                  )}
                />
              )}
              {selectedValue === 'demandManagement' && (
                <Controller
                  name='smartCharging.setPointkw'
                  control={props.siteAreaForm.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        onValueChangeAmp();
                      }}
                      id='maximumPower'
                      label={`${translate('Demand Setpoint (kW)')}`}
                      required={requiredFields['maximumPower']}
                      error={!!errors?.maximumPower}
                      helperText={`${translate(
                        props.siteAreaForm.formState?.errors?.maximumPower
                          ?.message || ' '
                      )}`}
                      fullWidth
                      variant='standard'
                    // disabled={props.isDisabled}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='maximumPower'
                control={props.siteAreaForm.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      onValueChangeAmp();
                    }}
                    id='maximumPower'
                    label={`${translate('Equivalent kW')}`}
                    required={requiredFields['maximumPower']}
                    error={!!errors?.maximumPower}
                    helperText={`${translate(
                      props.siteAreaForm.formState?.errors?.maximumPower
                        ?.message || ' '
                    )}`}
                    fullWidth
                    variant='standard'
                    disabled
                  // disabled={props.isDisabled}
                  />
                )}
              />
            </Grid>
          </HeaderWrapper>
        )}
        <HeaderWrapper title={'Site Area Configuration'}>
          <Grid item xs={4}>
            <Controller
              name='voltage'
              control={control}
              render={({ field }) => (
                <EvesSelect
                  data-cy='voltage-option'
                  label={`${translate('chargers.voltage')}`}
                  options={voltageOptions}
                  {...field}
                  onChange={(event: any) => {
                    field.onChange(event);
                    onValueChangeAmp();
                  }}
                // disabled={props.isDisabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={'numberOfPhases'}
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <EvesSelect
                  // disabled={
                  //   watch(`currentsmartCharging`) ==
                  //     'DC' ||
                  //   !props.canUpdateChargingStation ||
                  //   watch(`connectors.${index}.smartCharging`) == 'T1' ||
                  //   watch(`connectors.${index}.smartCharging`) == 'D'
                  // }
                  label={`${translate('chargers.nb_connected_phase')} *`}
                  options={connectedPhaseOptions}
                  {...field}
                  onChange={(event: any) => {
                    field.onChange(event);
                    numberOfConnectedPhaseChanged();
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={'phaseAssignment'}
              control={props.siteAreaForm.control}
              render={({ field }) => {
                return (
                  <EvesSelect
                    label={`${translate('chargers.phase_assignment')} *`}
                    options={phaseAssignementToGridOptions}
                    {...field}
                    error={
                      !!props.siteAreaForm.formState?.errors?.phaseAssignment
                    }

                    value={JSON.stringify(field.value)}
                    onChange={(event: any) => {
                      field.onChange(JSON.parse(event.target.value));
                      props.siteAreaForm.trigger('phaseAssignment');
                      props.siteAreaForm.setValue('shrink', true);
                    }}
                    shrink={props.siteAreaForm.watch('shrink')}
                  />
                );
              }}
            />
          </Grid>
        </HeaderWrapper>
        <HeaderWrapper title={'Site Area Rating'}>
          {['N', 'demandManagement'].includes(selectedValue) && <Grid item xs={4}>
            <Controller
              name='rating'
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    onValueChangeRating();
                  }}
                  required={requiredFields['rating']}
                  error={!!errors?.rating}
                  helperText={`${translate(
                    props.siteAreaForm.formState?.errors?.rating?.message ||
                    ' '
                  )}`}
                  id='rating'
                  label={`${translate(' Power (kW)')}`}
                  fullWidth
                  disabled={selectedValue !== 'loadBalancing' ? false : true}
                  variant='standard'
                />
              )}
            />
          </Grid>}
          <Grid item xs={4}>
            <Controller
              name='ampPerPhase'
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    onValueChangeAmp()
       
                  }}
                  required={requiredFields['ampPerPhase']}
                  error={!!errors?.ampPerPhase}
                  helperText={`${translate(
                    props.siteAreaForm.formState?.errors?.ampPerPhase
                      ?.message || ' '
                  )}`}
                  id='ampPerPhase'
                  label={`${translate('Amp Per Phase (A)')}`}
                  fullWidth
                  disabled={selectedValue !== 'loadBalancing' ? true : false}
                  variant='standard'
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name='totalAmpAllPhases'
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                
                  required={requiredFields['totalAmpAllPhases']}
                  error={!!errors?.totalAmpAllPhases}
                  helperText={`${translate(
                    props.siteAreaForm.formState?.errors?.totalAmpAllPhases
                      ?.message || ' '
                  )}`}
                  id='totalAmpAllPhases'
                  label={`${translate('Amp All Phases (A)')}`}
                  fullWidth
                  disabled
                  variant='standard'
                />
              )}
            />
          </Grid>
          {selectedValue === 'loadBalancing' && <Grid item xs={4}>
            <Controller
              name='rating'
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    // onValueChange();
                    onValueChangeRating()
     
                  }}
                  // required={requiredFields['rating']}
                  error={!!errors?.rating}
                  helperText={`${translate(
                    props.siteAreaForm.formState?.errors?.rating?.message ||
                    ' '
                  )}`}
                  id='rating'
                  label={`${translate(' Power (kW)')}`}
                  fullWidth
                  disabled={true}
                  variant='standard'
                />
              )}
            />
          </Grid>}
        </HeaderWrapper>
        <HeaderWrapper title={'Installed Capacity'}>
          <Grid item xs={4}>
            <Controller
              name='installedChargers'
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id='installedChargers'
                  label={`${translate('Installed number of chargers')}`}
                  fullWidth
                  disabled
                  variant='standard'
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name='totalInstallAmp'
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id='totalInstallAmp'
                  label={`${translate('Total Installed (A)')}`}
                  fullWidth
                  disabled
                  variant='standard'
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name='totalInstallKw'
              control={props.siteAreaForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id='totalInstallKw'
                  label={`${translate('Total Installed (kW)')}`}
                  fullWidth
                  disabled
                  variant='standard'
                />
              )}
            />
          </Grid>
        </HeaderWrapper>{' '}
      </Grid>
    </>
  );
}
